/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* plugin styles */
@import "~swiper/swiper-bundle";

@import "styles/ionic";
@import "styles/fonts";

.ion-page {
  background: #212121;
}

.mobile-wrapper {
  margin: auto;
  background: white;
  border-radius: 20px;
  width: var(--screen-width);
  height: var(--screen-height);

  // mimic safe area
  position: relative;
  overflow: hidden;
}

.viewport {
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  position: absolute;
}

bipu-wave-header {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

// topic page
.tabs-swiper {
  .swiper-slide {
    width: fit-content;
  }
}

// ripple effect
.ion-activatable {
  position: relative;
  overflow: hidden;
}

.viewport.hint {
  padding: 0 16px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  ion-icon {
    color: #004294;
    font-size: 60px;
    margin-bottom: 15px;
  }
}