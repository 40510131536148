.ion-activatable {
  overflow: hidden;
  position: relative;
}

.blur-modal {
  margin: auto;
  width: var(--screen-width);
  height: var(--screen-height);
  border-radius: 20px;
  overflow: hidden;

  .ion-page {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px);
  }
}

ion-modal::part(content) {
  height: inherit;
  background: transparent;
}
