@font-face {
  font-family: "Noto Sans Bold";
  src: url("../assets/fonts/NotoSans-Bold.ttf");
}

@font-face {
  font-family: "Noto Sans Italic";
  src: url("../assets/fonts/NotoSans-Italic.ttf");
}

@font-face {
  font-family: "Noto Sans BoldItalic";
  src: url("../assets/fonts/NotoSans-BoldItalic.ttf");
}

@font-face {
  font-family: "Noto Sans Regular";
  src: url("../assets/fonts/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  src: url("../assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../assets/fonts/Roboto-Regular.ttf");
}

.noto-bold {
  font-family: "Noto Sans Bold", sans-serif !important;
}

.noto-boldItalic {
  font-family: "Noto Sans BoldItalic", sans-serif;
}

.roboto-regular {
  font-family: "Roboto Regular", sans-serif;
}

.roboto-medium {
  font-family: "Roboto Medium", sans-serif;
}

.size-9 {
  font-size: min(9px, 3vw);
  line-height: 10px;
}

.size-10 {
  font-size: min(10px, 3.5vw);
  line-height: 12px;
}

.size-12 {
  font-size: min(12px, 4vw);
  line-height: 16px;
}

.size-14 {
  font-size: min(14px, 4.5vw);
  line-height: 18px;
}

.size-16 {
  font-size: min(16px, 5vw);
  line-height: 22px;
}

.size-18 {
  font-size: min(18px, 5.5vw);
  line-height: 24px;
}

.size-20 {
  font-size: min(20px, 6vw);
  line-height: 26px;
}

.size-24 {
  font-size: min(24px, 7vw);
  line-height: 30px;
}

.size-46 {
  font-size: min(46px, 12vw);
  line-height: 48px;
}

.size-48 {
  font-size: min(48px, 13vw);
  line-height: 56px;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.text-spacing-1 {
  letter-spacing: 1px;
}

.clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
